<template>
  <div class="bg">
    <div class="title">血糖检测</div>
    <div class="leftMain">
      <div class="leftMain_top">
        <div class="leftMain_topIn">f
          <ul>
            <li>
              <div class="liIn">
                <h3>总交易额</h3>
                <p class="shu"><span class="shu1">¥ {{ (homeAll.payAmount) }}</span></p>
                <div class="zi">
                  <div :style="{color:dataList.yesterday.payAmount>dataList.today.payAmount?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.payAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.payAmount)>Number(dataList.today.payAmount)"/>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.payAmount)<=Number(dataList.today.payAmount)"/>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>退款金额</h3>
                <p class="shu"><span class="shu2">¥ {{ (homeAll.refundAmount) }}</span><i></i></p>
                <div class="zi">
                  <div :style="{color:Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.refundAmount) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.refundAmount)>Number(dataList.today.refundAmount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.refundAmount)<Number(dataList.today.refundAmount)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>客单价</h3>
                <p class="shu"><span class="shu3">¥ {{ (homeAll.customerPrice) }}</span></p>
                <div class="zi">
                  <div :style="{color:Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)?'red':'green'}">
                    <span>昨日同时段{{ (dataList.yesterday.customerPrice) }}元</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.customerPrice)>Number(dataList.today.customerPrice)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.customerPrice)<Number(dataList.today.customerPrice)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
            <li>
              <div class="liIn">
                <h3>用户数</h3>
                <p class="shu"><span class="shu4">{{ homeAll.userCount || 0 }}</span></p>
                <div class="zi">
                  <div
                    :style="{color:Number(dataList.yesterday.userCount)>Number(dataList.userCount)?'red':'green'}">
                    <span>昨日同时段{{ dataList.yesterday.userCount || 0 }}个</span>
                    <a-icon type="arrow-down" v-if="Number(dataList.yesterday.userCount)>Number(dataList.today.userCount)"></a-icon>
                    <a-icon type="arrow-up" v-if="Number(dataList.yesterday.userCount)<Number(dataList.today.userCount)"></a-icon>
                  </div>
                </div>
                <span class="border_bg_leftTop"></span>
                <span class="border_bg_rightTop"></span>
                <span class="border_bg_leftBottom"></span>
                <span class="border_bg_rightBottom"></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="leftMain_middle">
        <div class="leftMain_middle_left">
          <div class="leftMain_middle_leftIn">
            <h3>订单交易额日统计</h3>
            <!-- 为ECharts准备一个具备大小（宽高）的Dom -->
            <div class="biaoge" style="width:100%; height:25vh" id="orderHistogramChart"></div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
        <div class="leftMain_middle_right">
          <div class="leftMain_middle_rightIn">
            <h3>明星销量排行榜</h3>
            <div class="biaoge biaoge_pai" style="width:100%; height:25vh">
              <div class="biaoge_paiIn">
                <ul>
                  <li v-for="(item,index) in this.starAmountList" :key="item.starId">
                    <div class="liIn">
                      <div class="liIn_left"><span class="bot"></span><span class="zi">{{item.starName}}</span>
                      </div>
                      <div class="liIn_line">
                        <div class="line_lineIn" :style="'width: '+ item.payAmountRate"></div>
                      </div>
                      <p class="num">{{item.payAmountRate}}</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
      </div>
      <div class="leftMain_middle">
        <div class="leftMain_middle_left">
          <div class="leftMain_middle_leftIn">
            <h3>订单销售额折线图</h3>
            <div class="biaoge" style="width:100%; height:25vh" id="orderLineChart"></div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
        <div class="leftMain_middle_right">
          <div class="leftMain_middle_rightIn">
            <h3>订单信息(今日)</h3>
            <div class="biaoge biaoge_bi" style="width:100%; height:25vh">
              <ul>
                <li>
                  <div class="liIn">
                    <p class="shu shu1">{{ dataList.today.orderCount }}</p>
                    <p class="zi">总订单数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu2">{{ dataList.today.refundCount }}</p>
                    <p class="zi">退款单数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu3">¥ {{ (dataList.today.goodsAmount) }}</p>
                    <p class="zi">商品金额</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu4">¥ {{ (dataList.today.shippingAmount) }}</p>
                    <p class="zi">运费</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu5">{{ dataList.today.userCount || 0 }}</p>
                    <p class="zi">今日新增用户数</p>
                  </div>
                </li>
                <li>
                  <div class="liIn">
                    <p class="shu shu6">{{ dataList.today.inviteUserCount || 0 }}</p>
                    <p class="zi">今日新增邀请用户数</p>
                  </div>
                </li>
              </ul>

            </div>
            <span class="border_bg_leftTop"></span>
            <span class="border_bg_rightTop"></span>
            <span class="border_bg_leftBottom"></span>
            <span class="border_bg_rightBottom"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="rightMain">
      <div class="rightMain_top">
        <div class="rightMain_topIn">
          <h3>用户地域分析</h3>
          <div class="biaoge" style="width:100%; height:30vh" id="chartmain_bing"></div>
          <!--          <script type="text/javascript">
                      option = {
                        title: {
                          text: '数据情况统计',
                          subtext: '',
                          left: 'right',
                          textStyle: {
                            color: '#fff',
                            fontSize: 12
                          }
                        },
                        tooltip: {
                          trigger: 'item',
                          formatter: '{a} <br/>{b} : {c} ({d}%)'
                        },
                        legend: {
                          // orient: 'vertical',
                          // top: 'middle',
                          type: 'scroll',
                          orient: 'vertical',
                          right: 10,
                          top: 40,
                          bottom: 20,
                          left: 'right',
                          data: ['西凉', '益州', '兖州', '荆州', '幽州'],
                          textStyle: {
                            color: '#fff',
                            fontSize: 12
                          }

                        },
                        grid: {
                          x: '-10%',
                          y: 40,
                          x2: 20,
                          y2: 20,
                        },
                        color: ['#09d0fb', '#f88cfb', '#95f8fe', '#f9f390', '#ecfeb7'],
                        series: [
                          {
                            type: 'pie',
                            radius: '65%',
                            center: ['50%', '50%'],
                            selectedMode: 'single',
                            data: [
                              {
                                value: 1548, name: '幽州',

                              },
                              {value: 535, name: '荆州'},
                              {value: 510, name: '兖州'},
                              {value: 634, name: '益州'},
                              {value: 735, name: '西凉'}
                            ],
                            emphasis: {
                              itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)'
                              }
                            }
                          }
                        ]
                      };
                      //获取dom容器
                      var myChart = echarts.init(document.getElementById('chartmain_bing'));
                      // 使用刚指定的配置项和数据显示图表。
                      myChart.setOption(option);

                    </script>-->
          <span class="border_bg_leftTop"></span>
          <span class="border_bg_rightTop"></span>
          <span class="border_bg_leftBottom"></span>
          <span class="border_bg_rightBottom"></span>
        </div>
      </div>
      <div class="rightMain_bottom">
        <div class="rightMain_bottomIn">
          <h3>用户消费排行榜</h3>
          <div class="biaoge biaoge_list" style="width:100%; height:36vh">
            <div class="biaoge_listIn">
              <ul class="ul_title">
                <li>用户</li>
                <li>手机号</li>
                <li>消费金额</li>
              </ul>
              <div class="ul_list">
                <div class="ul_listIn">
                  <ul class="ul_con" v-for="(item,index) in this.userPayList" :key="item.userId">
                    <li>{{item.nickName}}</li>
                    <li>{{item.mobile}}</li>
                    <li>{{item.payAmount}}</li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <span class="border_bg_leftTop"></span>
          <span class="border_bg_rightTop"></span>
          <span class="border_bg_leftBottom"></span>
          <span class="border_bg_rightBottom"></span>
        </div>
      </div>
    </div>
    <div style="clear:both;"></div>
  </div>
</template>

<script>
import {tableMixin} from '@/store/table-mixin'
import * as echarts from 'echarts'
import moment from 'moment';
import {orderAmountChart, orderTimeChart} from "@/api/statistics/order";
import {homeAllResult, homeResult} from "@/api/statistics/home";
import {userSta} from "@/api/statistics/user";
import {starSta} from "@/api/statistics/star";

export default {
  name: 'dashBroad',
  components: {},
  mixins: [tableMixin],
  data() {
    return {
      activeKey: 1,
      /*销售数据选择*/
      isSalesSelect: undefined,
      isCartSelect: undefined,
      dataList: {
        today:{},
        yesterday:{},
      },
      homeAll:{},
      userPayList:[],
      userPayParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        pageNum:1,
        pageSize:100,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      starAmountList:[],
      starAmountParam: {
        chartType: '1',
        startTime: undefined,
        endTime:undefined,
        pageNum:1,
        pageSize:6,
        sortField:'payAmount',
        sortOrder:'desc'
      },
      orderAmountParam: {
        chartType: 'days',
        dateRange: [],
        startTime: undefined,
        endTime:undefined,

      },
    }
  },
  filters: {},
  created() {
    this.getHomeStatistics();
    this.getHomeAllStatistics();
    this.drawUserPayList()
    this.drawStarAmountList()
  },
  mounted() {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 768 * 100 + 'px';
    this.orderHistogram();
    this.orderLineChart();
  },
  computed: {},
  watch: {},
  methods: {
    moment,
    changeTab(index) {
      if (index == null) {
        index = undefined;
      }
      this.activeKey = index;
    },
    getHomeStatistics() {
      homeResult().then(response => {
        this.dataList = response.data
      });
    },
    getHomeAllStatistics() {
      homeAllResult().then(response => {
        this.homeAll = response.data
      });
    },
    orderHistogram() {
      //指定图表的配置项和数据
      // // 添加
      var chartDom = document.getElementById("orderHistogramChart");
      var myChart = echarts.init(chartDom);
      var option;

      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = [];
          let xAxis = [];
          res.data.xxAxis.forEach(e=>{
            xAxis.push(e.substring(8,10)+"日");
          })
          res.data.yyAxis.payAmountList.forEach(e=>{
            yaxis.push(e/10000);
          })
          option = {
            title: {
              text: '',
              subtext: ''
            },
            grid: {
              x: 40,
              y: 40,
              x2: 20,
              y2: 20,

            },
            xAxis: {
              data: xAxis,
              axisLabel: {
                /*inside: true,*/
                interval: 0,
                textStyle: {
                  color: '#fff',
                  fontSize: 12
                }
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true,
                symbol: ['none', 'arrow'],
                symbolOffset: 12,
                lineStyle: {
                  color: '#fff',
                }
              },
              z: 10
            },
            yAxis: {
              type: 'value',
              name: '单位：万元',
              axisLine: {
                show: true,
                symbol: ['none', 'arrow'],
                symbolOffset: 12,
                lineStyle: {
                  color: '#fff',
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                textStyle: {
                  color: '#fff',
                  fontSize: 12
                }
              }
            },

            dataZoom: [
              {
                type: 'inside'
              }
            ],
            series: [
              { // For shadow
                type: 'bar',
                itemStyle: {
                  color: 'rgba(0,0,0,0.05)'
                },
                barGap: '-100%',
                barCategoryGap: '40%',
                animation: false
              },
              {
                type: 'bar',
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(
                    0, 0, 0, 1,
                    [
                      {offset: 0, color: '#0efdff'},
                      {offset: 0.5, color: '#188df0'},
                      {offset: 1, color: '#188df0'}
                    ]
                  )
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: function (params) {
                      return params.value;
                    },
                    //一下为label的一些配置项，设置外边框，背景颜色，字体颜色等
                    backgroundColor: 'rgba(61,126,255,0.09)',
                    borderColor: '#ff543d',
                    borderWidth: 0.5,
                    borderRadius: 2,
                    padding: 6,
                    position: 'top',
                    textStyle: {
                      color: '#ff543d',
                      fontSize: 14,
                      marginLeft: 0,
                      lineHeight: 20
                    }
                  },
                  itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                      0, 0, 0, 1,
                      [
                        {offset: 0, color: '#2378f7'},
                        {offset: 0.7, color: '#2378f7'},
                        {offset: 1, color: '#0efdff'}
                      ]
                    )
                  }
                },
                data: yaxis
              }
            ]
          }
          option && myChart.setOption(option);
        }
      });

      // Enable data zoom when user click bar.
      /*var zoomSize = 6;
      myChart.on('click', function (params) {
          console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
          myChart.dispatchAction({
              type: 'dataZoom',
              startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
              endValue: dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
          });
      });*/
    },

    orderLineChart() {
      // // 添加
      var chartDom = document.getElementById("orderLineChart");
      var myChart = echarts.init(chartDom);
      var option;
      orderAmountChart(this.orderAmountParam).then((res) => {
        if (res.code === "20001") {
          let yaxis = res.data.yyAxis;
          let xAxis = res.data.xxAxis;
          option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'cross',
                label: {
                  backgroundColor: '#6a7985'
                }
              }
            },
            legend: {
              data: ['订单销售额', '运费金额','退款金额']
            },
            toolbox: {
              feature: {
                saveAsImage: {}
              }
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: [
              {
                type: 'category',
                boundaryGap: false,
                data: xAxis,
                axisLabel: {
                  textStyle: {
                    color: '#fff',
                    fontSize: 12
                  }
                },
              }
            ],
            yAxis: [
              {
                type: 'value',
                axisLine: {
                  show: true,
                  symbol: ['none', 'arrow'],
                  symbolOffset: 12,
                  lineStyle: {
                    color: '#fff',
                  }
                },
              }
            ],
            series: [
              {
                name: '运费金额',
                type: 'line',
                stack: 'shippingAmount',
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(211,89,13,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(197,105,67,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                lineStyle: {
                  color: '#ee8e22' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#ee8e22' // 设置标点的颜色为蓝色
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.shippingPayAmountList
              },
              {
                name: '退款金额',
                type: 'line',
                stack: 'refundAmount',
                lineStyle: {
                  color: '#f30202' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#f30202' // 设置标点的颜色为蓝色
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(218,119,119,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(239,5,5,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.refundAmountList
              },
              {
                name: '订单销售额',
                type: 'line',
                stack: 'payAmount',
                lineStyle: {
                  color: '#46f302' // 这里设置
                },
                itemStyle: { // 设置标点的默认样式
                  normal: {
                    color: '#46f302' // 设置标点的颜色为蓝色
                  }
                },
                areaStyle: {
                  color: {
                    type: 'linear',
                    x: 0,
                    y: 0,
                    x2: 0,
                    y2: 1,
                    colorStops: [{
                      offset: 0, color: 'rgba(45,255,0,0.5)'    // 0% 处的颜色
                    }, {
                      offset: 1, color: 'rgba(123,203,96,0)' //   100% 处的颜色
                    }],
                    global: false // 缺省为 false
                  }
                },
                label: {
                  normal: {
                    show: true,
                    formatter: function(params) {
                      // 当数值为0时不显示标签
                      if (params.value === 0) {
                        return '';
                      } else if (params.value === '0.00') {
                        return '';
                      }
                      return params.value;
                    }
                  },
                },
                emphasis: {
                  focus: 'series'
                },
                data: yaxis.payAmountList
              },
            ]
          };


          option && myChart.setOption(option);
        }
      });
    },

    drawUserPayList() {
      userSta(this.userPayParam).then((res) => {
        this.userPayList = res.data.records
      });
    },
    drawStarAmountList() {
      starSta(this.starAmountParam).then((res) => {
        this.starAmountList = res.data.records
      });
    },
  }
}
</script>
<style lang="less" scoped>
.bg {
  margin: 0 auto;
  width: 100%;
  min-height: 100vh;
  background: url(images/bg2.jpg) no-repeat;
  background-size: cover;
  padding: 0rem 0.2rem;

}

.title {
  width: 100%;
  font-size: 0.12rem;
  line-height: 0.3rem;
  color: rgba(14, 253, 255, 1);
  text-align: center;
  font-weight: bold;
}

.border_bg_leftTop {
  position: absolute;
  left: -0.008rem;
  top: -0.04rem;
  width: 0.37rem;
  height: 0.05rem;
  display: block;
  background: #01279d url(images/title_left_bg.png) no-repeat;
  background-size: cover;
}

.border_bg_rightTop {
  position: absolute;
  right: -0.01rem;
  top: -0.01rem;
  width: 0.1rem;
  height: 0.1rem;
  display: block;
  background: url(images/border_bg.jpg) no-repeat;
  background-size: cover;
}

.border_bg_leftBottom {
  position: absolute;
  left: -0.008rem;
  bottom: -0.008rem;
  width: 0.1rem;
  height: 0.1rem;
  display: block;
  background: url(images/border_bg.jpg) no-repeat;
  background-size: cover;
}

.border_bg_rightBottom {
  position: absolute;
  right: -0.01rem;
  bottom: -0.01rem;
  width: 0.08rem;
  height: 0.08rem;
  display: block;
  background: url(images/title_right_bg.png) no-repeat;
  background-size: cover;
}

.leftMain {
  width: 75%;
  float: left;
  padding-right: 0.1rem;
  padding-top: 0.1rem;
}

.rightMain {
  width: 25%;
  float: left;
  padding-top: 0.1rem;
}

.leftMain_top {
  width: 100%;
  padding-bottom: 0.1rem;
}

.leftMain_top ul {
  display: flex;
  display: -webkit-flex;
}

.leftMain_top ul li {
  float: left;
  width: 25%;
  padding-right: 0.1rem;
}

.leftMain_top ul li:last-child {
  padding: 0;
}

.leftMain_top ul li .liIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_top ul li .liIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_top ul li .liIn .shu {
  font-size: 0.12rem;
  color: rgba(14, 253, 255, 1);
  font-family: dig;
  margin-bottom: 0.02rem;
}

.leftMain_top ul li .liIn .shu i {
  font-size: 0.04rem;
  margin-left: 0.06rem;
  font-style: normal;
}

.leftMain_top ul li .liIn .zi {
  font-size: 0.04rem;
  color: #fff;
  position: relative;
  z-index: 10;
}

.leftMain_top ul li .liIn .zi .span1 {
  margin-right: 0.1rem;
}


.leftMain_middle {
  width: 100%;
  padding-bottom: 0.1rem;
  display: flex;
  display: -webkit-flex;
}

.leftMain_middle .leftMain_middle_left {
  width: 60%;
  padding-right: 0.1rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_left .leftMain_middle_leftIn .biaoge {
  min-height: 200px;
}

.leftMain_middle .leftMain_middle_right {
  width: 40%;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge {
  min-height: 200px;
}

/*左边中间部分排行榜*/
.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai {
  width: 100%;
  overflow: hidden;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: #fff;
  font-size: 0.06rem;
  height: 0.18rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left {
  width: 25%;
  position: relative;
  padding-left: 0.14rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left .bot {
  width: 0.08rem;
  height: 0.08rem;
  background: #f78cf3;
  border-radius: 1000px;
  display: block;
  position: absolute;
  left: 0.02rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn2 .liIn_left .bot {
  background: #e7feb8;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn3 .liIn_left .bot {
  background: #fdea8a;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn4 .liIn_left .bot {
  background: #8ff9f8;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn5 .liIn_left .bot {
  background: #d890fa;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn6 .liIn_left .bot {
  background: #05d1fc;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_left zi {
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_line {
  width: 58%;
  height: 0.08rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 2000px;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .liIn_line .line_lineIn {
  width: 100%;
  height: 0.08rem;
  background: #f78cf3;
  border-radius: 100px;
  -webkit-animation: widthMove1 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn2 .liIn_line .line_lineIn {
  background: #e7feb8;
  -webkit-animation: widthMove2 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn3 .liIn_line .line_lineIn {
  background: #fdea8a;
  -webkit-animation: widthMove3 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn4 .liIn_line .line_lineIn {
  background: #8ff9f8;
  -webkit-animation: widthMove4 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn5 .liIn_line .line_lineIn {
  background: #d890fa;
  -webkit-animation: widthMove5 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn6 .liIn_line .line_lineIn {
  background: #05d1fc;
  -webkit-animation: widthMove6 2s ease-in-out;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_pai ul li .liIn .num {
  width: 17%;
  font-family: dig;
  padding-left: 0.02rem;
}

/*左边底部*/
.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  width: 100%;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li {
  width: 33.3%;
  text-align: center;
  margin-bottom: 0.05rem;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li .shu {
  font-size: 0.14rem;
  color: rgba(14, 253, 255, 1);
  font-family: dig;
  padding: 0.12rem 0 0.02rem;
  font-weight: normal;
}

.leftMain_middle .leftMain_middle_right .leftMain_middle_rightIn .biaoge_bi ul li .zi {
  font-size: 0.06rem;
  color: #fff;
}

/*右边部分*/
.rightMain .rightMain_top {
  width: 100%;
  padding-bottom: 0.1rem;
}

.rightMain .rightMain_topIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.rightMain .rightMain_topIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

.rightMain .rightMain_topIn .biaoge {
  min-height: 200px;
}

.rightMain .rightMain_bottom {
  width: 100%;
}

.rightMain .rightMain_bottomIn {
  border: 0.008rem solid rgba(14, 253, 255, 0.5);
  width: 100%;
  min-height: 60px;
  position: relative;
  padding: 0.08rem 0.05rem;
}

.rightMain .rightMain_bottomIn h3 {
  font-size: 0.08rem;
  color: #fff;
  margin-bottom: 0.05rem;
}

/*右下角表格*/
.rightMain .rightMain_bottomIn .biaoge {
  min-height: 200px;
}

.rightMain .rightMain_bottomIn .biaoge_list {
  overflow: hidden;
  position: relative;
}

.rightMain .rightMain_bottomIn .biaoge_list .biaoge_listIn .ul_list {
  overflow: hidden;
  position: relative;
}

.rightMain .rightMain_bottomIn .biaoge_list .biaoge_listIn .ul_listIn {
  -webkit-animation: 14s gundong linear infinite normal;
  animation: 14s gundong linear infinite normal;
  position: relative;
}

@keyframes gundong {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(0, -30vh, 0);
    transform: translate3d(0, -30vh, 0);
  }
}

.rightMain .rightMain_bottomIn .biaoge_list ul {
  display: flex;
  display: -webkit-flex;
  width: 100%;
}

.rightMain .rightMain_bottomIn .biaoge_list .ul_title {
  background: linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.1));
}

.rightMain .rightMain_bottomIn .biaoge_list .ul_con {
  border-bottom: 0.008rem solid rgba(14, 253, 255, 0.5);
}

.rightMain .rightMain_bottomIn .biaoge_list ul li {
  width: 30%;
  text-align: center;
  color: #fff;
  font-size: 0.06rem;
  height: 0.2rem;
  line-height: 0.2rem;
}
@keyframes cuIcon-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

[class*="cuIcon-"] {
  font-family: "cuIcon";
  font-size: inherit;
  font-style: normal;
}


/* CSS Document */
@font-face{
  font-family: 'dig';
  src : url('fonts/DIN-Light.otf'),url('fonts/DIN-Medium.otf'),url('fonts/DIN-Bold.otf');
}
body, p, div, h1, h2, h3, h4, h5, h6, button, input, ol, li, ul, dl, dt, dd, img, table, tr, td, th, select {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  border: 0;
  list-style: none;
  box-sizing: border-box;
}
a,img,button,input,textarea{-webkit-tap-highlight-color:rgba(255,255,255,0); outline-color:#0081ff; background:none;}
button{
  outline:none;
}
input::-webkit-input-placeholder {
  color:#aaa;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button{
  -webkit-appearance: none !important;
  margin: 0;
}
input[type="number"]{-moz-appearance:textfield;}
body{
  margin:0 auto;
  font-size:16px;
  color:#121212;
  font-family:"Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;
  position:relative;
}
a{
  text-decoration:none;
  color:#f06000;
}


#header{
  height:0.5rem;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
}
.header{
  height:0.5rem;
  width:100%;
  position:fixed;
  top:0;
  left:0;
  z-index:1000;
}
.headerIn{
  width:100%;
  height:100%;
  position:relative;
  background:#0081ff;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.headerIn .back{
  display: flex;
  height: 100%;
  align-items: center;
  padding-left:0.2rem;
  font-size:0.14rem;
  color:#fff;
  width:20%;
}
.headerIn .save{
  display: flex;
  height:65%;
  align-items: center;
  font-size:0.14rem;
  color:#fff;
  padding:0 0.2rem;
  text-align:right;
  justify-content:center;
  margin-right:0.1rem;
  border-radius:3px;
  border:1px solid #fff;
}

.cuIcon-back:before{
  margin-right:3px;
  font-size:0.16rem;}
.headerIn h2{
  width:50%;
  font-size:0.18rem;
  color:#fff;
  font-weight:normal;
  text-align:center;
  margin:0 auto;
  padding:0;
  font-family:"微软雅黑";
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  position:absolute;
  left:0;
  right:0;
  top:0;
  margin:auto;
  height:100%;
  line-height:0.5rem;}
.headerIn .sel{
  position:absolute;
  top:0;
  right:0;
  display:block;
  width:30%;
  height:100%;
  /*
	background:url(../images/icon_sel.png) no-repeat 90% center;
  */
  background-size:20px auto;
  padding-right:25px;
  color:#fff;
  line-height:45px;}
.headerIn .share{
  position:absolute;
  top:0;
  right:10px;
  display:block;
  width:60px;
  height:100%;
  /*
	background:url(../images/icon_share.png) no-repeat 5px center;
  */
  background-size:20px auto;
  padding-left:25px;
  color:#fff;
  line-height:45px;
  text-align:right;
  font-size:14px;}


body .spinner-android{
  display:none;}
body .loading{
  /*
  background:url(../images/loading2.gif) no-repeat center center;
  */
  background-size:60px auto;
  width:80px;
  height:80px;
  padding:10px;
}

body {
  /* Color 可以自定义相关配色 */
  /* var属性兼容性 --> https://www.caniuse.com/#feat=css-variables */
  /* 标准色 */
  --red: #e54d42;
  --orange: #f37b1d;
  --yellow: #fbbd08;
  --olive: #8dc63f;
  --green: #39b54a;
  --cyan: #1cbbb4;
  --blue: #0081ff;
  --purple: #6739b6;
  --mauve: #9c26b0;
  --pink: #e03997;
  --brown: #a5673f;
  --grey: #8799a3;
  --black: #333333;
  --darkGray: #666666;
  --gray: #aaaaaa;
  --ghostWhite: #f1f1f1;
  --white: #ffffff;
  /* 浅色 */
  --redLight: #fadbd9;
  --orangeLight: #fde6d2;
  --yellowLight: #fef2ce;
  --oliveLight: #e8f4d9;
  --greenLight: #d7f0db;
  --cyanLight: #d2f1f0;
  --blueLight: #cce6ff;
  --purpleLight: #e1d7f0;
  --mauveLight: #ebd4ef;
  --pinkLight: #f9d7ea;
  --brownLight: #ede1d9;
  --greyLight: #e7ebed;
  /* 渐变色 */
  --gradualRed: linear-gradient(45deg, #f43f3b, #ec008c);
  --gradualOrange: linear-gradient(45deg, #ff9700, #ed1c24);
  --gradualGreen: linear-gradient(45deg, #39b54a, #8dc63f);
  --gradualPurple: linear-gradient(45deg, #9000ff, #5e00ff);
  --gradualPink: linear-gradient(45deg, #ec008c, #6739b6);
  --gradualBlue: linear-gradient(45deg, #0081ff, #1cbbb4);
  /* 阴影透明色 */
  --ShadowSize: 6px 6px 8px;
  --redShadow: rgba(204, 69, 59, 0.2);
  --orangeShadow: rgba(217, 109, 26, 0.2);
  --yellowShadow: rgba(224, 170, 7, 0.2);
  --oliveShadow: rgba(124, 173, 55, 0.2);
  --greenShadow: rgba(48, 156, 63, 0.2);
  --cyanShadow: rgba(28, 187, 180, 0.2);
  --blueShadow: rgba(0, 102, 204, 0.2);
  --purpleShadow: rgba(88, 48, 156, 0.2);
  --mauveShadow: rgba(133, 33, 150, 0.2);
  --pinkShadow: rgba(199, 50, 134, 0.2);
  --brownShadow: rgba(140, 88, 53, 0.2);
  --greyShadow: rgba(114, 130, 138, 0.2);
  --grayShadow: rgba(114, 130, 138, 0.2);
  --blackShadow: rgba(26, 26, 26, 0.2);

  background-color: var(--ghostWhite);
  color: var(--black);
  font-family: Helvetica Neue, Helvetica, sans-serif;
}

/* -- 阴影 -- */

.shadow[class*='white'] {
  --ShadowSize: 0 1px 6px;
}

.shadow-lg {
  --ShadowSize: 0px 0.4rem 1rem 0px;
}

.shadow-warp {
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.shadow-warp:before,
.shadow-warp:after {
  position: absolute;
  content: "";
  top: 20px;
  bottom: 30px;
  left: 20px;
  width: 50%;
  box-shadow: 0 30px 20px rgba(0, 0, 0, 0.2);
  transform: rotate(-3deg);
  z-index: -1;
}

.shadow-warp:after {
  right: 20px;
  left: auto;
  transform: rotate(3deg);
}
/* ==================
          背景
 ==================== */

.line-red::after,
.lines-red::after {
  border-color: var(--red);
}

.line-orange::after,
.lines-orange::after {
  border-color: var(--orange);
}

.line-yellow::after,
.lines-yellow::after {
  border-color: var(--yellow);
}

.line-olive::after,
.lines-olive::after {
  border-color: var(--olive);
}

.line-green::after,
.lines-green::after {
  border-color: var(--green);
}

.line-cyan::after,
.lines-cyan::after {
  border-color: var(--cyan);
}

.line-blue::after,
.lines-blue::after {
  border-color: var(--blue);
}

.line-purple::after,
.lines-purple::after {
  border-color: var(--purple);
}

.line-mauve::after,
.lines-mauve::after {
  border-color: var(--mauve);
}

.line-pink::after,
.lines-pink::after {
  border-color: var(--pink);
}

.line-brown::after,
.lines-brown::after {
  border-color: var(--brown);
}

.line-grey::after,
.lines-grey::after {
  border-color: var(--grey);
}

.line-gray::after,
.lines-gray::after {
  border-color: var(--gray);
}

.line-black::after,
.lines-black::after {
  border-color: var(--black);
}

.line-white::after,
.lines-white::after {
  border-color: var(--white);
}

.bg-red {
  background-color: var(--red);
  color: var(--white);
}

.bg-orange {
  background-color: var(--orange);
  color: var(--white);
}

.bg-yellow {
  background-color: var(--yellow);
  color: var(--black);
}

.bg-olive {
  background-color: var(--olive);
  color: var(--white);
}

.bg-green {
  background-color: var(--green);
  color: var(--white);
}

.bg-cyan {
  background-color: var(--cyan);
  color: var(--white);
}

.bg-blue {
  background-color: var(--blue);
  color: var(--white);
}

.bg-purple {
  background-color: var(--purple);
  color: var(--white);
}

.bg-mauve {
  background-color: var(--mauve);
  color: var(--white);
}

.bg-pink {
  background-color: var(--pink);
  color: var(--white);
}

.bg-brown {
  background-color: var(--brown);
  color: var(--white);
}

.bg-grey {
  background-color: var(--grey);
  color: var(--white);
}

.bg-gray {
  background-color: #f0f0f0;
  color: var(--black);
}

.bg-black {
  background-color: var(--black);
  color: var(--white);
}

.bg-white {
  background-color: var(--white);
  color: var(--darkGray);
}

.bg-shadeTop {
  background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.01));
  color: var(--white);
}

.bg-shadeBottom {
  background-image: linear-gradient(rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 1));
  color: var(--white);
}

.bg-red.light {
  color: var(--red);
  background-color: var(--redLight);
}

.bg-orange.light {
  color: var(--orange);
  background-color: var(--orangeLight);
}

.bg-yellow.light {
  color: var(--yellow);
  background-color: var(--yellowLight);
}

.bg-olive.light {
  color: var(--olive);
  background-color: var(--oliveLight);
}

.bg-green.light {
  color: var(--green);
  background-color: var(--greenLight);
}

.bg-cyan.light {
  color: var(--cyan);
  background-color: var(--cyanLight);
}

.bg-blue.light {
  color: var(--blue);
  background-color: var(--blueLight);
}

.bg-purple.light {
  color: var(--purple);
  background-color: var(--purpleLight);
}

.bg-mauve.light {
  color: var(--mauve);
  background-color: var(--mauveLight);
}

.bg-pink.light {
  color: var(--pink);
  background-color: var(--pinkLight);
}

.bg-brown.light {
  color: var(--brown);
  background-color: var(--brownLight);
}

.bg-grey.light {
  color: var(--grey);
  background-color: var(--greyLight);
}

.bg-gradual-red {
  background-image: var(--gradualRed);
  color: var(--white);
}

.bg-gradual-orange {
  background-image: var(--gradualOrange);
  color: var(--white);
}

.bg-gradual-green {
  background-image: var(--gradualGreen);
  color: var(--white);
}

.bg-gradual-purple {
  background-image: var(--gradualPurple);
  color: var(--white);
}

.bg-gradual-pink {
  background-image: var(--gradualPink);
  color: var(--white);
}

.bg-gradual-blue {
  background-image: var(--gradualBlue);
  color: var(--white);
}

.bg-img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.bg-mask {
  background-color: var(--black);
  position: relative;
}

.bg-mask::after {
  content: "";
  border-radius: inherit;
  width: 100%;
  height: 100%;
  display: block;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}


/* ==================
          文本
 ==================== */

.text-white,
.line-white,
.lines-white {
  color: var(--white);
}
.bdCon{
  margin-top:0.5rem;}
.anniu_btn{
  width:100%;
  padding:0.2rem;
}
.anniu_btn button{
  background:#0081ff;
  height:0.4rem;
  border-radius:5px;
  color:#fff;
  width:50%;
  margin:0 auto;
  display:block;
  font-size:0.12rem;
  max-width:200px;}
@media only screen and (min-width: 1400px) {
  #header{
    height:0.3rem;
  }
  .header{
    height:0.3rem;
  }
  .headerIn .back{
    font-size:0.08rem;
  }
  .headerIn .save{
    font-size:0.08rem;
  }

  .cuIcon-back:before{
    margin-right:3px;
    font-size:0.1rem;}
  .headerIn h2{
    font-size:0.1rem;
    line-height:0.3rem;}
  .bdCon{
    margin-top:0.3rem;}
  .anniu_btn button{
    height:0.26rem;
    font-size:0.08rem;
    max-width:400px;}


}
@media screen and (max-width: 641px) {
  #header{
    height:1rem;
  }
  .header{
    height:1rem;
  }
  .headerIn .back{
    font-size:0.3rem;
  }
  .headerIn .save{
    font-size:0.3rem;
  }
  .cuIcon-back:before{
    margin-right:3px;
    font-size:0.3rem;}
  .headerIn h2{
    font-size:0.32rem;
    line-height:1rem;}
  .bdCon{
    margin-top:1rem;}
  .anniu_btn{
    padding:0.2rem 5%;}
  .anniu_btn button{
    height:1rem;
    font-size:0.3rem;
    max-width:100%;
    width:100%;
  }

}
</style>
