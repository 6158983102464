import request from '@/utils/request'


export function userSex(query) {
  return request({
    url: '/statistics/user/sex',
    method: 'get',
    params: query
  })
}

export function userAge(query) {
  return request({
    url: '/statistics/user/age',
    method: 'get',
    params: query
  })
}


export function userSexOrder(query) {
  return request({
    url: '/statistics/user/sex/order',
    method: 'get',
    params: query
  })
}

export function userAddressOrder(query) {
  return request({
    url: '/statistics/user/address/order',
    method: 'get',
    params: query
  })
}

export function userAgeOrder(query) {
  return request({
    url: '/statistics/user/age/order',
    method: 'get',
    params: query
  })
}

export function userActive(query) {
  return request({
    url: '/statistics/user/active',
    method: 'get',
    params: query
  })
}

export function userInvite(query) {
  return request({
    url: '/statistics/user/invite',
    method: 'get',
    params: query
  })
}

export function userSta(query) {
  return request({
    url: '/statistics/user/user/page',
    method: 'get',
    params: query
  })
}